// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// font-awesome
@import '~font-awesome';


.table th {
  font-weight: 600;
}

.table th, .table td{
  padding: 2px 6px;
}

html {
  font-size: 16px;
}

.calculator .form-control {
  height: calc(1.3em + 0.75rem + 2px) !important;
  font-size: 12px !important;
}

.calculator .card-body {
  padding: 10px 10px 10px;
}

.calculator .table {
  margin-bottom: 2px;
}

.calculator .card {
  border-radius: 15px !important;
}
.calculator .card-header {
  border-right: none !important;
}

.calculator .card-header:has(.collapsed) {
  border-radius: 15px !important;
}
.cursor-pointer{
  cursor: pointer!important;
}

.calculator .input-group-text {
  padding: .1rem .35rem;
  font-size: 12px;
}

.btn-calculate {
  background-color: #8dc63f !important;
  border-color: #8dc63f !important;
}
.btn-calculate:hover {
  background-color: #668f2e !important;
}

.bg-custom {
  background-color: #55585d;
}
.text-color-green {
  color: #8dc63f;
}

.calculator .frontline-logo {
  margin: auto 2px;
}

.calculator .table th, .calculator .table td {
  vertical-align: middle;
}

.calculator .frontline-logo img {
  width: 38%;
}

.calculator .form-control {
  padding: 0.375rem 0.3rem;
}

.calculator .w-70 {
  width: 70% !important;
}

.preloadloader {
  width: 100px;
  height: 80px;
  position: absolute;
  top: 0; right: 0; left: 0; bottom: 0;
  margin: auto;
}
.preloadloader .image {
  width: 100px;
  height: 160px;
  font-size: 40px;
  text-align: center;
  transform-origin: bottom center;
  animation: 3s rotate infinite;
  opacity: 0;
  margin-bottom: 30px;
}
.preloadloader span {
  display: block;
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 0;
}

@keyframes rotate{
  0% {
    transform: rotate(-90deg);
  }
  10% {
    opacity: 0;
  }
  35% {
    transform: rotate(0deg);
    opacity: 1;
  }
  65% {
    transform: rotate(0deg);
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    transform: rotate(90deg);
  }
}

.calculator-content {display:none;}
.preloadloader {
  display: block;
  width:100px;
  height: 100px;
  position: fixed;
}


@media (max-width: 575.98px) {
  .calculator .bg-custom {
    justify-content: center;
  }
  .calculator .frontline-logo {
    display: flex;
    justify-content: center;
  }
  .calculator .frontline-logo img {
    width: 75%;
  }
  .calculator .nav-heading {
    font-size: 11px !important;
  }
  .calculator h3.card-header {
    font-size: 20px;
    padding: 0.7rem;
  }
  .calculator .table th {
    padding: 2px 8px;
    vertical-align: center;
    width: 65% !important;
  }
  .calculator .table td {
    padding: 2px 8px;
    vertical-align: center;
    width: 35% !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (max-width: 767.98px) {
  .calculator .bg-custom {
    justify-content: center;
  }
  .calculator .frontline-logo {
    display: flex;
    justify-content: center;
  }
  .calculator .frontline-logo img {
    width: 40%;
  }
  .calculator .nav-heading {
    font-size: 22px;
  }
  .calculator h3.card-header {
    font-size: 20px;
    padding: 0.7rem;
  }
  .calculator .table th {
    padding: 2px 8px;
    vertical-align: center;
    width: 65% !important;
  }
  .calculator .table td {
    padding: 2px 8px;
    vertical-align: center;
    width: 35% !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
  .calculator .bg-custom {
    justify-content: center;
  }
  .calculator .frontline-logo {
    //display: flex;
    //justify-content: center;
  }
  .calculator .frontline-logo img {
    width: 40%;
  }
  .calculator .nav-heading {
    font-size: 22px;
  }
  .calculator h3.card-header {
    font-size: 20px;
    padding: 0.7rem;
  }
  .calculator .table th {
    padding: 2px 8px;
    vertical-align: center;
    width: 65% !important;
  }
  .calculator .table td {
    padding: 2px 8px;
    vertical-align: center;
    width: 35% !important;
  }
}

@media (max-width: 1199.98px) {
  .calculator .nav-heading {
    font-size: 22px;
  }
}